<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $t('History') }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/report">
                  <feather-icon
                    icon="FileTextIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                <span v-if="(memberData.firstname || memberData.lastname)">{{memberData.firstname}} {{memberData.lastname}}</span>
                <span v-else>{{memberData.username}}</span>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="justify-content-between">
      <b-col md="4" sm="6" class="my-1">
        &nbsp;
      </b-col>

      <b-col md="4" sm="6" class="my-1" style="text-align: end;">
        <div class="text-right" style="position: absolute;right: 0;margin-top: 25px;">
          <DatePicker popup-class="popup_month_al"  type="month" v-model="selectedMonth" placeholder="" :open.sync="open" @change="changeMonth" range
          :disabled-date="disabledBeforeTodayAndAfterAMonth"></DatePicker>
        </div>

        <b-form-group class="mb-0">
          <b-dropdown
              :text="date_filter_text"
              class="budget-dropdown"
              variant="outline-dark"
              size="md"
            >
            <b-dropdown-item href="#" @click="changedDateRange('montday')">
              {{ $t('Last 30 days') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="openMonth">
              {{ $t('Select a month') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" v-if="chkModelOption('bp')">
        <Chart-Sys :sysData="sysData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('bp')">
        <Chart-Dia :diaData="diaData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('bp')">
        <Chart-hert :heartData="heartData"/>
      </b-col>
      <b-col lg="12">
        <Chart-Alcohol :alcoholData="alcoholData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('temp')">
        <Chart-Temp :tempData="tempData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('sp_foot')">
        <Chart-Sensefoot :footData="footData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('sp_hand')">
        <Chart-Sensehand :handData="handData"/>
      </b-col>

      <b-col lg="12" v-if="chkModelOption('blind')">
        <Chart-Blind :blindData="blindData"/>
      </b-col>
      <b-col lg="12" v-if="chkModelOption('push_sp')">
        <ChartPushScore :pushScoreData="pushScoreData"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup,  
  BBreadcrumb, 
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import ChartBlood from "./ChartBlood.vue";
import ChartAlcohol from "./ChartAlcohol.vue";
import ChartSensefoot from "./ChartSensefoot.vue";
import ChartSensehand from "./ChartSensehand.vue";
import ChartHert from "./ChartHert.vue";
import ChartDia from "./ChartDia.vue";
import ChartSys from "./ChartSys.vue";
import ChartTemp from "./ChartTemp.vue";
import ChartBlind from "./ChartBlind.vue";
import ChartPushScore from "./ChartPushScore.vue";
import reportService from "@/services/reportService.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dateFormat from "dateformat";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BBreadcrumb, 
    BBreadcrumbItem,
    BDropdown,
    BDropdownItem,
    DatePicker,

    ChartBlood,
    ChartAlcohol,
    ChartSensefoot,
    ChartSensehand,
    ChartHert,
    ChartDia,
    ChartSys,
    ChartBlind,
    ChartTemp,
    ChartPushScore,
  },
  data() {
    let today = new Date();
    var endDate = dateFormat(today, "yyyy-mm-dd");

    return {
      open: false,
      date_filter_text: this.$t('Last 30 days'),
      date_filter_val: {
        date_st: moment().subtract(30,'d').format('YYYY-MM-DD'),
        date_end: endDate,
      },
      selectedMonth: "",
      filterDateRange: "",
      memberData: {},
      modelOption: [],
      sysData: [],
      diaData: [],
      heartData: [],
      alcoholData: [],
      tempData: [],
      footData: [],
      handData: [],
      blindData: [],
      pushScoreData: [],
    };
  },
  async created() {
    await this.getChartdata();
  },
  methods: {
    openMonth() {
      this.open = true
    },
    changeMonth() {
      this.changedDateRange('selectmonth')
    },
    disabledBeforeTodayAndAfterAMonth(date) {
      return  date > new Date();
    },
    changedDateRange(range){
       
      this.filterDateRange = '';
      let today = new Date();
      var endDate = dateFormat(today, "yyyy-mm-dd");

      if(range == 'montday') {
        this.date_filter_val.date_st = moment().subtract(30,'d').format('YYYY-MM-DD')
        this.date_filter_val.date_end = endDate
        this.filterDateRange = "startDate=" + this.date_filter_val.date_st + "&endDate=" + this.date_filter_val.date_end;
        this.date_filter_text = this.$t('Last 30 days')
        this.selectedMonth = ""

      } else if(range == 'selectmonth') {

        let int_mst = dateFormat(this.selectedMonth[0], "m")
        let int_men = dateFormat(this.selectedMonth[1], "m")
        if ((int_men-int_mst) > 2) {
          this.$swal("เลือกสูงสุดได้ 3 เดือน!!!", "ขออภัยในความไม่สะดวก", "error");
          return false;
        }
        
        this.date_filter_val.date_st = dateFormat(this.selectedMonth[0], "yyyy-mm-01")
        this.date_filter_val.date_end = dateFormat(this.selectedMonth[1], "yyyy-mm-31")
        this.filterDateRange = "startDate=" + this.date_filter_val.date_st + "&endDate=" + this.date_filter_val.date_end;
        let m_st = dateFormat(this.selectedMonth[0], "mmm yyyy")
        let m_en = dateFormat(this.selectedMonth[1], "mmm yyyy")
        if (m_st == m_en) {
          this.date_filter_text = m_en
        } else {
          this.date_filter_text = dateFormat(this.selectedMonth[0], "mmm") +' - '+m_en
        }

      }
      this.getChartdata();
    },
    chkModelOption(val) {
      var res = false
      if (this.modelOption) { 
        let position = this.modelOption.indexOf(val);
        if (position > 0){
          res = true
        }
      } else {
        res = true
      }
      return res;
    },
    getChartdata() {
      let filterAll =
      "?" + this.filterDateRange;

      reportService
        .getdata(this.$route.params.id, filterAll)
        .then((response) => {
          let chartData = response.data.data;
          this.memberData = response.data.member;
          this.modelOption = response.data.modelOptions

          this.sysData = []
          this.diaData = []
          this.heartData = []
          this.alcoholData = []
          this.tempData = []
          this.footData = []
          this.handData = []
          this.blindData = []
          this.pushScoreData = []
          chartData.forEach((item) => {

              if (item.sys > 0) {
                  let v = { x: item.created_at, y: item.sys };
                  this.sysData.push(v);
              }

              if (item.dia > 0) {
                  let v = { x: item.created_at, y: item.dia };
                  this.diaData.push(v);
              }

              if (item.heart_rate > 0) {
                  let v = { x: item.created_at, y: item.heart_rate };
                  this.heartData.push(v);
              }

              if (item.package == 'albp' || item.package == 'al') {
                  let alcohol = 0;
                  if (item.alcohol > 0) {
                      alcohol = item.alcohol;
                  }
                  let v = { x: item.created_at, y: alcohol };
                  this.alcoholData.push(v);
              }

              let v = { x: item.created_at, y: item.temp };
              this.tempData.push(v);

              if (item.option_foot == 1) {
                  let speed_foot = ''
                  var speed_foot_arr = []
                  if (item.speed_foot > 0) {
                      speed_foot = item.speed_foot

                  } else {
                      if (item.speed_foot1 > 0.75) {
                          speed_foot_arr.push(item.speed_foot1);
                      }
                      if (item.speed_foot2 > 0.75) {
                          speed_foot_arr.push(item.speed_foot2);
                      }
                      if (item.speed_foot3 > 0.75) {
                          speed_foot_arr.push(item.speed_foot3);
                      }

                      if (speed_foot_arr.length > 0) {
                          speed_foot = Math.min(...speed_foot_arr);
                      }
                  }
                  let v = { x: item.created_at, y: speed_foot };
                  this.footData.push(v);
              }

              if (item.option_hand == 1) {
                  let speed_hand = ''
                  var speed_hand_arr = []
                  if (item.speed_hand > 0) {
                      speed_hand = item.speed_hand

                  } else {
                      if (item.speed_hand1 > 0.75) {
                          speed_hand_arr.push(item.speed_hand1);
                      }
                      if (item.speed_hand2 > 0.75) {
                          speed_hand_arr.push(item.speed_hand2);
                      }
                      if (item.speed_hand3 > 0.75) {
                          speed_hand_arr.push(item.speed_hand3);
                      }
                      if (speed_hand_arr.length > 0) {
                          speed_hand = Math.min(...speed_hand_arr);
                      }
                  }
                  let v = { x: item.created_at, y: speed_hand };
                  this.handData.push(v);
              }

              if (item.option_blind == 1) {
                  let blind = 0;
                  if (item.blind > 0) {
                      blind = item.blind;
                  }
                  let v = { x: item.created_at, y: blind };
                  this.blindData.push(v);
              }

              if (item.push_speed > 0) {
                  let v = { x: item.created_at, y: item.push_speed };
                  this.pushScoreData.push(v);
              }
              
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";

.dropdown-menu.show {
  z-index: 20;
}
.mx-range-wrapper > div:nth-child(2) {
  display: none !important;
}

.mx-input-wrapper input.mx-input {
  padding: 0;
  border: 0;
  box-shadow: unset;
  color: transparent;
  background: transparent;
}
.mx-icon-calendar, .mx-icon-clear {
  display: none;
}
</style>
