<template>
  <b-card no-body>
    <b-card-header>
      <!-- <b-card-title>Blood Pressure </b-card-title> -->
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <!-- <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-center"
          />
        </template> -->
        <!-- <b-dropdown-item value=""></b-dropdown-item> -->
        <!-- <b-dropdown-item href="#"> 7 วัน</b-dropdown-item>
        <b-dropdown-item href="#"> 15 วัน </b-dropdown-item>
        <b-dropdown-item href="#"> 1 เดือน </b-dropdown-item> -->
        <!-- <b-dropdown-item
          :value="item.ranges"
          v-for="item in ranges"
          :key="item"
        >
          {{ item }}
        </b-dropdown-item> -->
      </b-dropdown>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50"> This Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ revenue.thisMonth }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50"> Last Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ revenue.lastMonth }}</span>
          </h3>
        </div>
      </div> -->

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="450"
        :options="revenueComparisonLine.chartOptions"
        :series="revenueComparisonLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import reportService from "@/services/reportService.js";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            height: 600,
            type: "line",
            zoom: {
              enabled: false,
            },
            // dropShadow: {
            //   enabled: true,
            //   color: "#000",
            //   top: 18,
            //   left: 7,
            //   blur: 10,
            //   opacity: 0,
            // },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            // toolbar: {
            //   show: false,
            // },
          },
          colors: ["#ff1c4d", "#1cceff", "#3fdb0b", "#f50f0f"],

          // dataLabels: {
          //   enabled: true,
          // },
          // stroke: {
          //   curve: "smooth",
          // },
          title: {
            text: "Blood Presure",
            align: "left",
          },

          // grid: {
          //   borderColor: "#e7e7e7",
          //   row: {
          //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          //     opacity: 0.5,
          //   },
          // },
          markers: {
            size: 5,
          },
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val) {
                return moment(val).format("DD.MM.YYYY HH:mm");
              },
            },
            title: {
              text: this.$t('Time'),
            },
          },
          yaxis: [
            {
              title: {
                text: "ค่าที่วัด",
              },
            },
          ],
          // legend: {
          //   position: "top",
          //   horizontalAlign: "right",
          //   floating: true,
          //   offsetY: -25,
          //   offsetX: -5,
          // },
        },
      },
    };
  },
  created() {
    this.getChartdata();
    // this.$http.get("/card/card-analytics/revenue").then((res) => {
    //   this.revenue = res.data;
    // });
  },
  methods: {
    getChartdata() {
      console.log(this.$route.params.id);

      reportService
        .getdata(this.$route.params.id)
        .then((response) => {
          let data = response.data.data;
          let ser = [];
          let ser2 = [];
          let line1 = [];
          let line2 = [];

          data.forEach((item) => {
            if (item.sys > 0) {
              // let v = item.dia; //{ x: item.created_at, y: item.dia };
              ser.push({ x: item.created_at, y: item.dia });
              ser2.push({ x: item.created_at, y: item.sys });

              line1.push({ x: item.created_at, y: 140 });
              line2.push({ x: item.created_at, y: 90 });
            }
          });

          console.log(this.revenueComparisonLine.series);

          this.revenueComparisonLine.series.push({
            data: ser,
          });

          this.revenueComparisonLine.series.push({
            data: ser2,
          });
          this.revenueComparisonLine.series.push({
            data: line1,
          });
          this.revenueComparisonLine.series.push({
            data: line2,
          });
          // this.revenueComparisonLine.series = [
          //   {
          //     data: ser,
          //   },
          // ];
        })
        .catch(() => {});
    },
  },
};
</script>
