<template>
  <b-card no-body>
    <b-card-header class="text-center">
      <span class="mb-2" style="width: 100%;"><b>{{ $t("Alcohol in body") }}</b></span>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50"> This Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ revenue.thisMonth }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50"> Last Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ revenue.lastMonth }}</span>
          </h3>
        </div>
      </div> -->

      <!-- apex chart -->
      <div v-if="alcoholData.length > 0">
        <vue-apex-charts
          type="line"
          height="400"
          :options="revenueComparisonLine.chartOptions"
          :series="revenueComparisonLine.series"
        />
      </div>
      <div v-else class="text-center mb-3">
        <!-- <span class="mb-5"><b>{{ $t("Alcohol in body") }}</b></span><br><br> -->
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    alcoholData: {
    },
  },
  data() {
    return {
      datax: [],
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          legend: {
            show: false
          },
          markers: {
            size: [5, 8],
          },
          colors: ["#26a0fc", "#ff0000"],
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

          title: {
            text: "",
            align: "center",
          },
          tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="px-1 py-50 text-center">' +
                "<span><span style='border-bottom: 1px solid rgba(0, 0, 0, 0.125);font-size: 10px;'>" +
                w.globals.categoryLabels[dataPointIndex] +
                '</span><br> ' +
                '<span style="font-weight: 500;font-size: 12px;margin-left: 8px;"><span class="badge bg-primary" style="border-radius: 26px;font-size: 5px;height: 8px;width: 8px;position: absolute;left: 0;bottom: 11px;margin-left: 10px;padding: 0;">&nbsp;</span> ' +
                series[seriesIndex][dataPointIndex] +
                " mg%</span></span>" +
                "</div>"
              );
            }
          },
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val) {
                return moment(val).format("DD.MM.YYYY HH:mm");
              },
            },
            title: {
              text: this.$t('Time'),
            },
          },
          yaxis: {
            title: {
              text: "ระดับปริมาณแอลกอฮอล",
            },
          },
        },
      },
    };
  },
  async created() {
    this.getChartdata()
  },
  watch: {
    alcoholData() {
      this.getChartdata()
    }
  },
  methods: {
    getChartdata() {
      let over_arr = this.alcoholData.map((d) => {
        let val_chk = null
        if (d.y > 0 ) {
          val_chk = d.y
        }
        return {
          x: d.x,
          y: val_chk,
        };
      });

      this.revenueComparisonLine.series = [
        {
          name: 'mg%',
          type: "line",
          data: this.alcoholData,
        },
        {
          name: 'over',
          type: "scatter",
          data: over_arr,
        },
      ];
       
    },
  },
};
</script>
