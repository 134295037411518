<template>
  <b-card no-body>
    <b-card-header>
      <!-- <b-card-title>Dia </b-card-title> -->
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <!-- <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-center"
          />
        </template> -->
        <!-- <b-dropdown-item value=""></b-dropdown-item> -->
        <!-- <b-dropdown-item href="#"> 7 วัน</b-dropdown-item>
        <b-dropdown-item href="#"> 15 วัน </b-dropdown-item>
        <b-dropdown-item href="#"> 1 เดือน </b-dropdown-item> -->
        <!-- <b-dropdown-item
          :value="item.ranges"
          v-for="item in ranges"
          :key="item"
        >
          {{ item }}
        </b-dropdown-item> -->
      </b-dropdown>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50"> This Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ revenue.thisMonth }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50"> Last Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ revenue.lastMonth }}</span>
          </h3>
        </div>
      </div> -->

      <!-- apex chart -->
      <div v-if="diaData.length > 0">
        <vue-apex-charts
          type="line"
          height="400"
          :options="revenueComparisonLine.chartOptions"
          :series="revenueComparisonLine.series"
        />
      </div>
      <div v-else class="text-center mb-3">
        <span class="mb-5"><b>{{ $t('DIA') }}</b></span><br><br>
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
     
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    diaData: {
    },
  },
  data() {
    return {
      datax: [],
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          legend: {
            show: false
          },
          markers: {
            size: [5, 8],
          },

          title: {
            text: "Dia",
            align: "center",
          },
          tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="px-1 py-50 text-center">' +
                "<span><span style='border-bottom: 1px solid rgba(0, 0, 0, 0.125);font-size: 10px;'>" +
                w.globals.categoryLabels[dataPointIndex] +
                '</span><br> ' +
                '<span style="font-weight: 500;font-size: 12px;margin-left: 8px;"><span class="badge bg-primary" style="border-radius: 26px;font-size: 5px;height: 8px;width: 8px;position: absolute;left: 0;bottom: 11px;margin-left: 10px;padding: 0;">&nbsp;</span> ' +
                series[seriesIndex][dataPointIndex] +
                " mmHg</span></span>" +
                "</div>"
              );
            }
          },
          colors: ["#26a0fc", "#ff0000"],
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val) {
                return moment(val).format("DD.MM.YYYY HH:mm");
              },
            },
            title: {
              text: this.$t('Time'),
            },
          },
          yaxis: [
            {
              max: 180,
              min: 0,
              title: {
                text: "ค่าที่วัด",
              },
            },
          ],
          annotations: {
            position: "back",
            yaxis: [
              {
                label: {
                  text: "",
                },
                y: 60,
                y2: 90,
                fillColor: "#06F959FF",
              },
              {
                label: {
                  text: "",
                },
                y: 0,
                y2: 59,
                fillColor: "#F9060AFF",
              },
              {
                label: {
                  text: "",
                },
                y: 91,
                y2: 200,
                fillColor: "#F9060AFF",
              },
            ],
          },
        },
      },
    };
  },
  async created() {
    this.getChartdata()
  },
  watch: {
    diaData() {
      this.getChartdata()
    }
  },
  methods: {
    getChartdata() { 
      let over_arr = this.diaData.map((d) => {
        let val_chk = null
        if (d.y < 60 || d.y > 90 ) {
          val_chk = d.y
        }
        return {
          x: d.x,
          y: val_chk,
        };
      });

      this.revenueComparisonLine.series = [
        {
          name: 'dia',
          type: "line",
          data: this.diaData,
        },
        {
          name: 'dia_over',
          type: "scatter",
          data: over_arr,
        },
      ];
    },
  },
};
</script>
