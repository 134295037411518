<template>
  <b-card no-body>
    <b-card-header class="text-center">
      <span class="mb-2" style="width: 100%;"><b>การทดสอบความไว</b></span>
    </b-card-header>

    <b-card-body class="pb-0">
     
      <!-- apex chart -->
      <div v-if="pushScoreData.length > 0">
        <vue-apex-charts
          type="line"
          height="400"
          :options="revenueComparisonLine.chartOptions"
          :series="revenueComparisonLine.series"
        />
      </div>
      <div v-else class="text-center mb-3">
        <span class="mb-5"><b>การทดสอบความไว</b></span><br><br>
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    pushScoreData: {
    },
  },
  data() {
    return {
      datax: [],
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          legend: {
            show: false
          },
          markers: {
            size: [5, 8],
          },
          colors: ["#26a0fc", "#ff0000"],
          title: {
            align: "center",
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span> '}${
                data.series[data.seriesIndex][data.dataPointIndex]
              } คะแนน</span></div>`
            },
          },
          // grid: {
          //   row: {
          //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          //     opacity: 0.5,
          //   },
          // },
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val) {
                return moment(val).format("DD.MM.YYYY HH:mm");
              },
            },
            title: {
              text: this.$t('Time'),
            },
          },
          yaxis: [
            {
              max: 30,
              min: 0,

              title: {
                text: "ค่าที่วัด",
              },
            },
          ],
          //   annotations: {
          //     position: "back",
          //     yaxis: [
          //       {
          //         label: {
          //           text: "",
          //         },
          //         y: 60,
          //         y2: 90,
          //         fillColor: "#06F959FF",
          //       },
          //       {
          //         label: {
          //           text: "",
          //         },
          //         y: 0,
          //         y2: 60,
          //         fillColor: "#F9060AFF",
          //       },
          //       {
          //         label: {
          //           text: "",
          //         },
          //         y: 90,
          //         y2: 200,
          //         fillColor: "#F9060AFF",
          //       },
          //     ],
          //   },
        },
      },
    };
  },
  created() {
    this.getChartdata();
  },
  watch: {
    pushScoreData() {
      this.getChartdata()
    }
  },
  methods: {
     getChartdata() {
      let over_arr = this.pushScoreData.map((d) => {
        let val_chk = null
        if (d.y > 0.75 ) {
          val_chk = d.y
        }
        return {
          x: d.x,
          y: val_chk,
        };
      });
      this.revenueComparisonLine.series = [
        {
          name: 'ผ่าน',
          type: "line",
          data: this.pushScoreData,
        },
        {
          name: 'over',
          type: "scatter",
          data: over_arr,
        }
      ];
    },
  },
};
</script>
