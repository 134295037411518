<template>
  <b-card no-body>
    <b-card-header class="text-center">
      <span class="mb-2" style="width: 100%;"><b>{{ $t('Speed foot Title') }}</b></span>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50"> This Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ revenue.thisMonth }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50"> Last Month </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ revenue.lastMonth }}</span>
          </h3>
        </div>
      </div> -->

      <!-- apex chart -->
      <div v-if="footData.length > 0">
        <vue-apex-charts
          type="line"
          height="400"
          :options="revenueComparisonLine.chartOptions"
          :series="revenueComparisonLine.series"
        />
      </div>
      <div v-else class="text-center mb-3">
        <!-- <span class="mb-5"><b>{{ $t('Speed foot Title') }}</b></span><br><br> -->
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    footData: {
    },
  },
  data() {
    return {
      datax: [],
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          legend: {
            show: false
          },
          markers: {
            size: [5, 8],
          },
          colors: ["#26a0fc", "#ff0000"],
          title: {

            align: "center",
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>ความไว '}${
                data.series[data.seriesIndex][data.dataPointIndex]
              } s</span></div>`
            },
          },
          xaxis: {
            type: "category",
            labels: {
              formatter: function (val) {
                return moment(val).format("DD.MM.YYYY HH:mm");
              },
            },
            title: {
              text: this.$t('Time'),
            },
          },
          yaxis: [
            {
              max: 2.0,
              title: {
                text: "ค่าที่วัด",
              },
            },
          ],
          annotations: {
            position: "back",
            yaxis: [
              {
                label: {
                  text: "",
                },
                y: 0,
                y2: 0.75,
                fillColor: "#06F959FF",
              },
              {
                label: {
                  text: "",
                },
                y: 0.75,
                y2: 1.5,
                fillColor: "red",
              },
            ],
          },
        },
      },
    };
  },
  created() {
    this.getChartdata();
  },
  watch: {
    footData() {
      this.getChartdata()
    }
  },
  methods: {
     getChartdata() {
      let over_arr = this.footData.map((d) => {
        let val_chk = null
        if (d.y > 0.75 ) {
          val_chk = d.y
        }
        return {
          x: d.x,
          y: val_chk,
        };
      });
      this.revenueComparisonLine.series = [
        {
          name: 'ความไว',
          type: "line",
          data: this.footData,
        },
        {
          name: 'over',
          type: "scatter",
          data: over_arr,
        },
      ];
    },
  },
};
</script>
